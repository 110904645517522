/* PrimeReact CSS Override */

.p-panel .p-panel-header {
    display: inherit;
}

.p-panel .p-panel-header .p-panel-icons {
    float: left;
}

body .p-panel .p-panel-header .p-panel-header-icon {
    margin-right: 5px;
    align-items: inherit;
    height: 1em;
}
